const config = {
  apiGateway: {
    REGION: 'eu-west-1',
    PHY_LOCATION_APIGATEWAY_URL: 'https://5x4v0dbci3.execute-api.eu-west-1.amazonaws.com/prod',
    INTERNAL_PHY_LOCATION_APIGATEWAY_URL: 'https://9yizckf15g.execute-api.eu-west-1.amazonaws.com/prod',
    COMPANY_APIGATEWAY_URL: 'https://9nlsj2i0ad.execute-api.eu-west-1.amazonaws.com/prod',
  },
  reactApp: {
    VERSION: 'taxonomy-physical-location-1.12.1',
    HOSTNAME: 'https://locations.macquarie.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.macquarie.forwoodsafety.com',
    WEBSOCKET: 'wss://nycrtszsrd.execute-api.eu-west-1.amazonaws.com/prod'
  },
};

export default config;